import {
  AlertProps,
  type ThemeConfig,
  defineStyle,
  defineStyleConfig,
  extendTheme,
} from '@chakra-ui/react';

import { alertAnatomy, modalAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};

const extendedFAB = defineStyle({
  size: { base: 'sm', md: 'md' },
  bottom: '80px',
  right: 3,
  rounded: {
    base: 'full',
    lg: 'md',
  },
  position: {
    base: 'fixed',
    lg: 'static',
  },
  background: 'primary.500',
  color: 'neutral.0',
  _hover: { background: 'primary.600' },
  _active: { background: 'primary.700' },
  zIndex: 100,
  WebkitTapHighlightColor: 'transparent',
});

const activeButton = defineStyle({
  color: 'primary.500',
  border: '1px',
  borderColor: 'neutral.300',
  fontSize: 'sm',
  fontWeight: 'semibold',
  bg: 'primary.100',
});

const inactiveButton = defineStyle({
  border: '1px',
  borderColor: 'gray.300',
  fontSize: 'sm',
  fontWeight: 'semibold',
  '@media (hover: hover) and (pointer: fine)': {
    _hover: { bg: 'neutral.100' },
  },
});

export const buttonTheme = defineStyleConfig({
  variants: {
    extendedFAB,
    active: activeButton,
    inactive: inactiveButton,
  },
});
const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const ghostModal = definePartsStyle({
  dialog: {
    bg: 'transparent',
    border: 0,
    boxShadow: 'none',
    color: 'neutral.0',
  },
});

export const modalTheme = defineMultiStyleConfig({
  variants: { ghost: ghostModal },
});

const tabsTheme = {
  defaultProps: {
    colorScheme: 'primary',
  },
};

const inputTheme = {
  defaultProps: {
    colorScheme: 'primary',
    focusBorderColor: 'primary.500',
  },
};

const textAreaTheme = {
  defaultProps: {
    colorScheme: 'primary',
    focusBorderColor: 'primary.500',
  },
};

const switchTheme = {
  defaultProps: {
    colorScheme: 'primary',
  },
};

const {
  definePartsStyle: defineAlertPartsStyle,
  defineMultiStyleConfig: defineAlertMultiStyleConfig,
} = createMultiStyleConfigHelpers(alertAnatomy.keys);

const alertBaseStyle = defineAlertPartsStyle((props: AlertProps) => {
  const { status } = props;

  const successBase = status === 'success' && {
    container: {
      background: 'success',
    },
  };

  const infoBase = status === 'info' && {
    container: {
      background: 'primary.500',
    },
  };

  const loadingBase = status === 'loading' && {
    container: {
      background: 'primary.500',
    },
  };

  return {
    ...successBase,
    ...infoBase,
    ...loadingBase,
  };
});
const alertTheme = defineAlertMultiStyleConfig({ baseStyle: alertBaseStyle });

const breakpoints = {
  sm: '320px',
  md: '835px',
  lg: '960px',
  xl: '1200px',
  '2xl': '1536px',
};

const customizeTheme = {
  colors: {
    neutral: {
      0: '#FFFFFF',
      50: '#F5F5F6',
      100: '#EBECEE',
      200: '#DEE0E3',
      300: '#D1D4D8',
      400: '#AAAFB7',
      500: '#6E7681',
      600: '#6E7681',
      700: '#3A3E44',
      800: '#191C1F',
      900: '#060607',
    },
    // TODO: デザイナーが来られたら、色をきちんと決めてもらう
    primary: {
      25: '#E0F4F1',
      50: '#E0F4F1',
      100: '#E0F4F1',
      200: '#BFDBCC',
      300: '#BFDBCC',
      400: '#BFDBCC',
      500: '#69C7B8',
      600: '#69C7B8',
      700: '#69C7B8',
      800: '#69C7B8',
      900: '#69C7B8',
    },
    gray: {
      0: '#FFFFFF',
      50: '#F5F5F6',
      100: '#EBECEE',
      200: '#DEE0E3',
      300: '#D1D4D8',
      400: '#AAAFB7',
      500: '#6E7681',
      600: '#6E7681',
      700: '#3A3E44',
      800: '#191C1F',
      900: '#060607',
    },
    blue: {
      25: '#E9F4FF',
      50: '#E3F3FF',
      100: '#D1E9FF',
      200: '#84CAFF',
      300: '#2E90FA',
      400: '#1570EF',
      500: '#175CD3',
      600: '#1151BF',
      700: '#0A3B9C',
      800: '#073481',
      900: '#012B75',
    },
    red: {
      50: '#FFF5F5',
      100: '#FED7D7',
      200: '#FEB2B2',
      300: '#FC8181',
      400: '#F56565',
      500: '#E53E3E',
      600: '#C53030',
      700: '#9B2C2C',
      800: '#822727',
      900: '#63171B',
    },
    error: {
      25: '#E9F4FF',
      50: '#E9F4FF',
      500: '#F04438',
      900: '#c0362c',
    },
    warning: {
      25: '#E9F4FF',
      50: '#E9F4FF',
      500: '#F79009',
      900: '#c57307',
    },
    success: '#12B76A',
    transparent: 'rgba(0,0,0,0)',
  },
  breakpoints,
  config,
  components: {
    Button: buttonTheme,
    Modal: modalTheme,
    Tabs: tabsTheme,
    Input: inputTheme,
    Textarea: textAreaTheme,
    Switch: switchTheme,
    Alert: alertTheme,
  },
  styles: {
    global: {
      body: {
        backgroundColor: '#FFF',
      },
    },
  },
};

const theme = extendTheme(
  { ...customizeTheme }
  // withDefaultColorScheme({
  //   colorScheme: 'primary'
  // })
);

export default theme;
